<template>
  <div>
    <profile-loading v-if="loading"></profile-loading>
    <div v-else>
      <div class="card-align">
        <div class="p-col-12 p-lg-6 p-md-12 style">
          <div class="card1">
            <profile-card
              :profile="profile"
              @updatedProfileCard="updatedProfileCard"
            />
          </div>
        </div>

        <div class="p-col-12 p-lg-6 p-md-12 style">
          <div class="card1">
            <personal-details
              :profile="profile"
              :update-profile="updateProfile"
            ></personal-details>
          </div>
        </div>
        <div class="p-col-12 p-lg-6 p-md-12 p-flex-column style">
          <div class="card2 p-mb-3">
            <basic-details
              :profile="profile"
              :update-profile="updateProfile"
            ></basic-details>
          </div>
          <div class="card2">
            <education-details
              :profile="profile"
              :update-profile="updateProfile"
            ></education-details>
          </div>
        </div>

        <div class="p-col-12 p-lg-6 p-md-12 style">
          <div class="card2">
            <family-details
              :profile="profile"
              :update-profile="updateProfile"
            ></family-details>
          </div>
        </div>

        <div class="p-col-12 p-lg-6 p-md-12 style">
          <div class="card3">
            <contact-details
              :profile="profile"
              :update-profile="updateProfile"
              :show-details="false"
              @changedNo="changedNo"
            ></contact-details>
          </div>
        </div>
        <div class="p-col-12 p-lg-6 p-md-12 style">
          <div class="card3">
            <social-details
              :profile="profile"
              :update-profile="updateProfile"
            ></social-details>
          </div>
        </div>
      </div>
      <delete-profile-dialog class="p-my-3 delete-btn" />
    </div>
  </div>
</template>

<script>
import PersonalDetails from '@/components/myprofile/personalDetails/PersonalDetails.vue';
import BasicDetails from '@/components/myprofile/basicDetails/BasicDetails.vue';
import ContactDetails from '@/components/myprofile/contactDetails/ContactDetails.vue';
import familyDetails from '@/components/myprofile/familyDetails/familyDetails.vue';
import socialDetails from '@/components/myprofile/socialDetails/socialDetails.vue';
import educationDetails from '@/components/myprofile/educationDetails/educationDetails.vue';
import profileCard from '@/components/myprofile/profileCard.vue';
import { mapActions, mapGetters } from 'vuex';
import ProfileLoading from '@/components/views/ProfileLoading.vue';
import DeleteProfileDialog from '@/components/DeleteProfileDialog.vue';

export default {
  components: {
    PersonalDetails,
    familyDetails,
    BasicDetails,
    socialDetails,
    educationDetails,
    ContactDetails,
    profileCard,
    ProfileLoading,
    DeleteProfileDialog,
  },
  data() {
    return {
      loading: true,
      showDialog: false,
      profile: {
        contact_relation: '',
        contact_name: '',
        email: '',
        primary_contact_number: '',
        about: '',
        address_country: '',
        address_state: '',
        age: 0,
        created_for: '',
        date_of_birth: '',
        diet: '',
        display_photo_url: '',
        education: {
          attributes: {
            highest_qualification: '',
          },
        },
        family: {
          attributes: {
            father: '',
            mother: '',
            father_status: '',
            mother_status: '',
            family_type: '',
            no_of_married_brothers: 0,
            no_of_married_sisters: 0,
            no_of_unmarried_brothers: 0,
            no_of_unmarried_sisters: 0,
          },
        },
        first_name: '',
        gender: '',
        is_active: false,
        is_admin: false,
        is_approved: false,
        last_name: '',
        marital_status: '',
        mother_tongue: '',
        occupation: {
          attributes: {
            salary: 0,
            job_type: '',
          },
        },
        physical_info: {
          attributes: {
            weight: 0,
            height: 0.0,
            medical_conditions: '',
            differently_abled: null,
          },
        },
        profile_photo_url: [],
        uploaded_photos_count: 0,
        sent_interest: false,
        shortlisted: false,
        social_class: {
          attributes: {
            religion: '',
            caste: '',
            sub_caste: '',
            citizenship: '',
          },
        },
      },
      intervalid: '',
      intervalCount: 0,
    };
  },
  computed: {
    ...mapGetters(['selectOptions', 'authUser']),
  },
  watch: {
    intervalCount: function (newCount, _) {
      if (newCount == 5) {
        clearInterval(this.intervalid);
      }
    },
  },
  created() {
    this.loading = true;
    if (Object.keys(this.selectOptions).length === 0) {
      this.$store.dispatch('getSelectOptions');
    }
  },
  beforeUnmount() {
    if (this.intervalid !== undefined && this.intervalid !== '') {
      clearInterval(this.intervalid);
    }
  },
  async mounted() {
    this.getProfile();
    this.loading = true;
  },
  methods: {
    ...mapActions(['setAuthUser', 'logOut']),
    loadProfileData(from, to) {
      for (let i of Object.keys(from)) {
        if (from[i] !== null) {
          to[i] = from[i];
        }
      }
    },
    getProfile() {
      let that = this;
      this.$http.get('/users/my_profile').then((response) => {
        that.setAuthUser(response.data.data.attributes);
        that.loadProfileData(response.data.data.attributes, that.profile);
        that.loading = false;
      });
    },
    async updateProfile(obj, successMessage) {
      let that = this;
      return this.$http
        .patch(`users/${that.authUser.slug}`, {
          user: obj,
        })
        .then((res) => {
          that.setAuthUser(res.data.data.attributes);
          that.loadProfileData(res.data.data.attributes, that.profile);
          that.$moshaToast(successMessage, {
            type: 'success',
            showIcon: true,
            timeout: 2000,
          });
          return true;
        })
        .catch(() => {
          this.$moshaToast('Could not save data!', {
            type: 'danger',
            showIcon: true,
            timeout: 3000,
          });
          return false;
        });
    },
    updatedProfileCard(res, photo_upload = false) {
      this.intervalCount = 0;
      this.loadProfileData(res.data.data.attributes, this.profile);
      this.dob = res.data.data.attributes.date_of_birth
        ? res.data.data.attributes.date_of_birth.slice(0, 10)
        : 'NA';
      var that = this;

      if (photo_upload) {
        this.intervalid = setInterval(() => {
          that.intervalCount = that.intervalCount + 1;
          that.getProfile();
        }, 60000);
      }
    },
    changedNo(num) {
      this.profile.primary_contact_number = num;
      this.updateProfile(this.profile);
    },
  },
};
</script>

<style scoped lang="scss">
.delete-btn {
  margin-left: 10px;
}
.card-align {
  display: flex;
  flex-wrap: wrap;
}
.style {
  display: -webkit-flex;
}
.card1 {
  min-height: 13em;
  border-radius: 3px;
  background: $backgroundColor;
  border: solid 1px $profileCardBorder;
  padding: 1rem;
  width: 100%;
}
.card2 {
  border-radius: 3px;
  background: $backgroundColor;
  border: solid 1px $profileCardBorder;
  padding: 1rem;
  width: 100%;
}
.card3 {
  min-height: 13em;
  border-radius: 3px;
  background: $backgroundColor;
  border: solid 1px $profileCardBorder;
  padding: 1rem;
  width: 100%;
}
</style>
<style lang="scss">
.edit-profile-updatebut-style {
  width: max-content;
  margin-left: auto;
  margin-top: 1rem;
  td {
    width: auto;
  }
}
</style>
