<template>
  <form @submit.prevent="update">
    <table class="table-style edit-social-details c-form">
      <tr>
        <td>Religion*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="attributes.religion"
                class="p-ml-2 dropdown-style"
                type="text"
                :options="selectOptions.religions"
                option-label="display"
                option-value="value"
              />
            </div>
            <small
              v-if="
                onSave && v$.$error && v$.attributes.religion.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Please select one option</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>Caste*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="attributes.caste"
                :filter="true"
                class="p-ml-2 dropdown-style"
                type="text"
                :options="selectOptions.castes"
                option-label="display"
                option-value="value"
                @change="getCasteName"
              />
            </div>
            <small
              v-if="
                onSave && v$.$error && v$.attributes.caste.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Please select one option</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>Sub Caste</td>
        <td>
          :
          <Dropdown
            v-model="attributes.sub_caste"
            :filter="true"
            class="p-ml-2 dropdown-style"
            type="text"
            :options="subCasteNames"
            option-label="display"
            option-value="value"
          />
        </td>
      </tr>
      <tr>
        <td>Citizenship*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="attributes.citizenship"
                class="p-ml-2 dropdown-style"
                type="text"
                :options="selectOptions.citizenships"
                option-label="display"
                option-value="value"
              />
            </div>
            <small
              v-if="
                onSave &&
                v$.$error &&
                v$.attributes.citizenship.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Please select one option</small
            >
          </div>
        </td>
      </tr>
    </table>
    <div class="butwidth-style">
      <div class="edit-profile-updatebut-style">
        <td>
          <Button type="submit" :disabled="loading" class="my-profile-btn">
            <span class="p-button-label"
              ><i v-if="loading" class="fa fa-spinner fa-spin p-mr-2" />Save
            </span>
          </Button>
        </td>
        <td>
          <Button
            label="Cancel"
            :disabled="loading"
            class="p-button-outlined my-profile-btn"
            @click="editClose"
          />
        </td>
      </div>
    </div>
  </form>
</template>
<script>
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { updateProfileMixin } from '@/mixins/updateProfileMixin.js';
export default {
  mixins: [updateProfileMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
    updateProfile: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  emits: ['close'],
  data() {
    return {
      onSave: false,
      v$: useValidate(),
      subCasteNames: null,
      attributes: {
        religion: '',
        caste: '',
        sub_caste: '',
        citizenship: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['selectOptions']),
  },
  created() {
    this.attributes = { ...this.profile.social_class.attributes };
    if (this.attributes.caste) {
      this.getCasteName();
    }
  },
  mounted() {
    this.v$.$validate();
  },
  validations() {
    return {
      attributes: {
        religion: { required },
        caste: { required },
        citizenship: { required },
      },
    };
  },
  methods: {
    getCasteName() {
      let that = this;
      this.$http
        .get(`/users/sub_castes_names?caste_name=${this.attributes.caste}`)
        .then((response) => {
          that.subCasteNames = response.data;
        });
      if (
        this.profile.social_class.attributes.caste !== this.attributes.caste
      ) {
        this.attributes.sub_caste = null;
      }
    },
    update() {
      this.onSave = true;
      if (!this.v$.$error) {
        this.loading = true;
        this.updateProfile(
          { social_class_attributes: this.attributes },
          'Social details updated successfully'
        ).then((result) => {
          this.loading = false;
          if (result) {
            this.$emit('close');
          }
        });
      }
    },
    editClose() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.edit-social-details {
  .p-inputtext {
    padding: 0.2rem 0.5rem;
  }
}
</style>
<style scoped lang="scss">
.input-align {
  display: flex;
}
.input-width {
  width: 100%;
}
.text-color {
  color: $errorColor;
}
.butwidth-style {
  width: 100%;
}
button {
  height: 38px;
}
.table-style {
  width: 80%;
}
h3 {
  width: 80%;
}
td {
  display: inline-flex;
  width: 50%;
  padding: 7px;
  padding-bottom: 0;
}
input {
  width: 92%;
}
.dropdown-style {
  width: 92%;
  height: 22px;
}
.p-button-outlined {
  border-color: $borderColor;
  color: $labelColor;
  justify-content: center;
}
</style>
