<template>
  <div>
    <div v-if="mobile !== '' && showDetails">
      <Dialog
        v-model:visible="outsideClick"
        :breakpoints="{ '960px': '75vw' }"
        :dismissable-mask="true"
        :modal="true"
        class="verification-dialog"
        :draggable="false"
      >
        <header>
          <span>Alert!</span>
        </header>
        <section class="body">
          <p>You need to verify OTP again. Do you want to continue?</p>
          <div class="btn">
            <Button
              label="No"
              class="p-button-outlined option-btn btn-mr"
              @click="editClose"
            />
            <Button
              class="option-btn"
              type="submit"
              label="Yes"
              @click="otpVerification"
            />
          </div>
        </section>
      </Dialog>
    </div>
    <div v-if="otpMobileNoDialog || mobile === ''">
      <Dialog
        v-model:visible="outsideClick"
        :breakpoints="{ '660px': '95vw' }"
        :dismissable-mask="true"
        :modal="true"
        class="verification-dialog"
        :draggable="false"
      >
        <div class="mobileVerification">
          <header>
            <div><span>OTP Verification</span></div>
            <div class="can-pos">
              <Button
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-outlined"
                @click="editClose"
              />
            </div>
          </header>
          <section>
            <form @submit.prevent="sendOtp()">
              <p>We will send you an One Time Password on this mobile number</p>
              <div class="validation-align">
                <div>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"> +91 </span>
                    <InputNumber
                      v-model="mobile_no"
                      placeholder="Enter Mobile Number"
                      class="int-field"
                      :use-grouping="false"
                    />
                  </div>
                  <br />
                  <small
                    v-if="
                      submitClicked &&
                      v$.$error &&
                      v$.mobile_no.required.$invalid
                    "
                    class="text-color"
                  >
                    Mobile Number is Required</small
                  >
                  <small
                    v-if="
                      v$.mobile_no.numeric.$invalid ||
                      v$.mobile_no.minLength.$invalid ||
                      v$.mobile_no.maxLength.$invalid
                    "
                    class="text-color"
                  >
                    Please enter a valid mobile number</small
                  >
                </div>
                <div>
                  <Button
                    class="btn-ml"
                    label="Send OTP"
                    :loading="loading"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </section>
        </div>
      </Dialog>
    </div>
    <div v-if="otpDialog">
      <Dialog
        v-model:visible="outsideClick"
        :breakpoints="{ '960px': '75vw' }"
        :dismissable-mask="true"
        :modal="true"
        class="otp-dialog"
        :draggable="false"
      >
        <header>
          <div class="mobileVerification">
            <div><span>OTP Verification</span></div>
            <div class="can-pos">
              <Button
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-outlined"
                @click="editClose"
              />
            </div>
          </div>
        </header>
        <section class="body">
          <form @submit.prevent="verifyOtp(otp_no, mobile_no)">
            <p>Enter the OTP sent to {{ mobile_no }}</p>
            <InputText
              v-model="otp_no"
              class="int-width"
              placeholder="Enter Valid OTP"
            ></InputText>
            <div class="p-d-flex p-jc-center p-mt-3 p-mb-3">
              <div class="size-p">Didn't receive the OTP?</div>
              <vue-countdown
                v-if="countDownEnd === false"
                v-slot="{ minutes, seconds }"
                :time="60 * 1000"
                class="count-down"
                @end="countDownEnd = true"
              >
                {{
                  minutes.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })
                }}
                :
                {{
                  seconds.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })
                }}
              </vue-countdown>
              <div
                v-if="countDownEnd === true"
                class="resend-btn size-p"
                @click="
                  sendOtp();
                  countDownEnd = false;
                "
              >
                Resend again
              </div>
            </div>
            <div class="btn">
              <Button
                label="Change Number"
                class="p-button-outlined"
                @click="backToMobileNo"
              />
              <Button
                class="btn-ml"
                label="Verify"
                :loading="loading"
                @click="verifyOtp(otp_no, mobile_no)"
              />
            </div>
          </form>
        </section>
      </Dialog>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { required, minLength, maxLength, numeric } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
export default {
  props: {
    mobile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['close', 'changedNo'],
  data() {
    return {
      countDownEnd: false,
      submitClicked: false,
      outsideClick: true,
      showDetails: true,
      v$: useValidate(),
      otpDialog: false,
      otpMobileNoDialog: false,
      mobile_no: null,
      otp_no: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['authUser']),
  },
  watch: {
    outsideClick() {
      if (!this.outsideClick) {
        this.$emit('close');
      }
    },
  },
  mounted() {
    this.v$.$validate();
  },
  validations() {
    return {
      mobile_no: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
    };
  },
  methods: {
    editClose() {
      this.$emit('close');
    },
    otpVerification() {
      this.showDetails = false;
      this.otpMobileNoDialog = true;
    },
    sendOtp() {
      this.loading = true;
      this.submitClicked = true;
      if (!this.v$.$error) {
        let that = this;
        this.$http
          .get(
            `/users/${this.authUser.slug}/send_otp?mobile_no=${this.mobile_no}`
          )
          .then(() => {
            that.otpMobileNoDialog = false;
            that.otpDialog = true;
            that.loading = false;
            that.$moshaToast('Successfully sent OTP to this mobile Number', {
              type: 'success',
              showIcon: true,
              timeout: 3000,
            });
          })
          .catch(() => {
            that.loading = false;
            that.$moshaToast('Failed to send OTP. Please try again', {
              type: 'danger',
              showIcon: true,
              timeout: 3000,
            });
          });
      } else {
        this.loading = false;
      }
    },
    verifyOtp(otp_no, mob_no) {
      this.loading = true;
      let that = this;
      this.$http
        .get(`/users/${that.authUser.slug}/verify_otp?otp_no=${otp_no}`)
        .then(() => {
          that.$emit('changedNo', mob_no);
          that.$emit('close');
          that.loading = false;
          that.$moshaToast('Your mobile number is verified!', {
            type: 'success',
            showIcon: true,
            timeout: 3000,
          });
        })
        .catch(() => {
          that.loading = false;
          that.$moshaToast('Failed OTP Verification!', {
            type: 'danger',
            showIcon: true,
            timeout: 3000,
          });
        });
    },
    backToMobileNo() {
      this.otpMobileNoDialog = true;
      this.otpDialog = false;
    },
  },
};
</script>
<style lang="scss">
.mobileVerification {
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
  }
}
.verification-dialog {
  width: 40rem;
  .p-dialog-header {
    display: none;
  }
}
.otp-dialog {
  width: 40rem;
  .p-dialog-header {
    display: none;
  }
}
</style>
<style scoped lang="scss">
.size-p {
  font-size: 14px;
}
.p-inputgroup-addon {
  font: unset;
}
.count-down {
  margin-left: 10px;
  color: $primaryColor;
  font-size: 16px;
}
.resend-btn {
  margin-left: 10px;
  color: $primaryColor !important;
  cursor: pointer;
}
.optDialog {
  padding-top: 20px;
}
.fon-size {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
}
.option-btn {
  width: 4.5rem;
}
.int-width {
  width: 95%;
  max-width: 274px;
}
.int-field {
  width: 195px;
}
@media screen and (max-width: 468px) {
  .int-field {
    width: 185px;
  }
  .btn-ml {
    margin-left: -10px;
    font-size: small;
    height: 32px;
  }
}
.text-color {
  color: $errorColor;
}
.validation-align {
  display: flex;
  justify-content: center;
}
span {
  font-size: 18px;
  font-weight: bolder;
}
p {
  font-size: 16px;
  margin-bottom: 16px;
  color: $labelColor;
  text-align: center;
}
.can-pos {
  position: absolute;
  top: 0;
  right: 0;
  padding: 17px;
}
.btn-mr {
  margin-right: 20px;
}
.btn-ml {
  margin-left: 20px;
}
.btn {
  text-align: center;
}
.body {
  text-align: center;
}

header {
  text-align: center;
  color: $primaryColor;
  width: 100%;
  padding: 1rem;
  padding-top: 28px;
}
</style>
