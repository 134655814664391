<template>
  <form @submit.prevent="update">
    <table class="table-style edit-education-details c-form">
      <tr>
        <td>Maximum Education*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="education.highest_qualification"
                class="p-ml-2 dropdown-style"
                type="text"
                :options="selectOptions.educations"
                option-label="display"
                option-value="value"
              />
            </div>
            <small
              v-if="
                onSave &&
                v$.$error &&
                v$.education.highest_qualification.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Please select one option</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>Occupation*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="occupation.job_type"
                class="p-ml-2 dropdown-style"
                type="text"
                :options="selectOptions.jobs"
                option-label="display"
                option-value="value"
              />
            </div>
            <small
              v-if="
                onSave && v$.$error && v$.occupation.job_type.required.$invalid
              "
              class="text-color p-ml-3"
              >Please select one option</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>Monthly Take Home/Earnings*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <InputNumber
                v-model="occupation.salary"
                class="p-ml-2 number-box"
                mode="currency"
                currency="INR"
                currency-display="code"
                locale="en-IN"
              />
            </div>
            <small
              v-if="
                onSave && v$.$error && v$.occupation.salary.required.$invalid
              "
              class="text-color p-ml-3"
              >Monthly Earnings is Required</small
            >
          </div>
        </td>
      </tr>
    </table>
    <div class="butwidth-style">
      <div class="edit-profile-updatebut-style">
        <td>
          <Button type="submit" :disabled="loading" class="my-profile-btn">
            <span class="p-button-label"
              ><i v-if="loading" class="fa fa-spinner fa-spin p-mr-2" />Save
            </span>
          </Button>
        </td>
        <td>
          <Button
            label="Cancel"
            :disabled="loading"
            class="p-button-outlined my-profile-btn"
            @click="editClose"
          />
        </td>
      </div>
    </div>
  </form>
</template>
<script>
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { updateProfileMixin } from '@/mixins/updateProfileMixin.js';
export default {
  mixins: [updateProfileMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
    updateProfile: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  emits: ['close'],
  data() {
    return {
      onSave: false,
      v$: useValidate(),
      occupation: {
        job_type: '',
        salary: '',
      },
      education: {
        highest_qualification: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['selectOptions']),
  },
  validations() {
    return {
      occupation: {
        salary: { required },
        job_type: { required },
      },
      education: {
        highest_qualification: { required },
      },
    };
  },
  created() {
    this.occupation = { ...this.profile.occupation.attributes };
    this.education = { ...this.profile.education.attributes };
  },
  mounted() {
    this.v$.$validate();
  },
  methods: {
    update() {
      this.onSave = true;
      if (!this.v$.$error) {
        if (this.occupation.job_type === 'not_working') {
          this.occupation.salary = '0';
        }
        this.loading = true;
        this.updateProfile(
          {
            education_attributes: this.education,
            occupation_attributes: this.occupation,
          },
          'Education details updated successfully'
        ).then((result) => {
          this.loading = false;
          if (result) {
            this.$emit('close');
          }
        });
      }
    },
    editClose() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.edit-education-details {
  .p-inputtext {
    padding: 0.2rem 0.5rem;
  }
  .p-inputnumber-input {
    width: 92%;
  }
}
</style>
<style scoped lang="scss">
.input-align {
  display: flex;
}
.input-width {
  width: 100%;
}
.text-color {
  color: $errorColor;
}
.number-box {
  width: 92%;
}
.butwidth-style {
  width: 100%;
}
button {
  height: 38px;
}
.table-style {
  width: 80%;
}
h3 {
  width: 80%;
}
td {
  display: inline-flex;
  width: 50%;
  padding: 7px;
  padding-bottom: 0;
}
input {
  width: 92%;
}
.dropdown-style {
  width: 92%;
  height: 22px;
}
.p-button-outlined {
  border-color: $borderColor;
  color: $labelColor;
  justify-content: center;
}
</style>
