<template>
  <form @submit.prevent="update">
    <table class="table-style edit-family-details c-form">
      <tr>
        <td>Father Name</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <InputText
                v-model.trim="family_attributes.father"
                class="p-ml-2"
                type="text"
              />
            </div>
            <small
              v-if="v$.family_attributes.father.charSpaceDot.$invalid"
              class="text-color p-ml-3 p-d-block"
              >Only alphabets, space and dot(.) are allowed</small
            >
          </div>
        </td>
      </tr>
      <tr>
        <td>Mother Name</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <InputText
                v-model.trim="family_attributes.mother"
                class="p-ml-2"
                type="text"
              />
            </div>
            <small
              v-if="v$.family_attributes.mother.charSpaceDot.$invalid"
              class="text-color p-ml-3 p-d-block"
              >Only alphabets, space and dot(.) are allowed</small
            >
          </div>
        </td>
      </tr>
      <tr>
        <td>Father Status</td>
        <td>
          :
          <Dropdown
            v-model="family_attributes.father_status"
            class="p-ml-2 dropdown-style"
            type="integer"
            :options="selectOptions.father_statuses"
            option-label="display"
            option-value="value"
          />
        </td>
      </tr>
      <tr>
        <td>Mother Status</td>
        <td>
          :
          <Dropdown
            v-model="family_attributes.mother_status"
            class="p-ml-2 dropdown-style"
            type="integer"
            :options="selectOptions.mother_statuses"
            option-label="display"
            option-value="value"
          />
        </td>
      </tr>
      <tr>
        <td>Family Type</td>
        <td>
          :
          <Dropdown
            v-model="family_attributes.family_type"
            class="p-ml-2 dropdown-style"
            type="integer"
            :options="selectOptions.family_types"
            option-label="display"
            option-value="value"
          />
        </td>
      </tr>
      <tr>
        <td>No Of Married Sisters</td>
        <td>
          :
          <InputNumber
            v-model="family_attributes.no_of_married_sisters"
            class="p-ml-2 number-box"
            type="integer"
          />
        </td>
      </tr>
      <tr>
        <td>No Of Married Brothers</td>
        <td>
          :
          <InputNumber
            v-model="family_attributes.no_of_married_brothers"
            class="p-ml-2 number-box"
            type="integer"
          />
        </td>
      </tr>
      <tr>
        <td>No Of Unmarried Sisters</td>
        <td>
          :
          <InputNumber
            v-model="family_attributes.no_of_unmarried_sisters"
            class="p-ml-2 number-box"
            type="integer"
          />
        </td>
      </tr>
      <tr>
        <td>No Of Unmarried Brothers</td>
        <td>
          :
          <InputNumber
            v-model="family_attributes.no_of_unmarried_brothers"
            class="p-ml-2 number-box"
            type="integer"
          />
        </td>
      </tr>
      <tr>
        <td>Country*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="basic.address_country"
                :filter="true"
                class="p-ml-2 dropdown-style"
                type="text"
                :options="selectOptions.country_names"
                option-label="display"
                option-value="value"
                @change="getCountryStates"
              />
            </div>
            <small
              v-if="
                onSave &&
                v$.$error &&
                v$.basic.address_country.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Please select one option</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>State*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="basic.address_state"
                :filter="true"
                :empty-message="
                  stateLoading ? 'Loading....' : 'Select your country'
                "
                class="p-ml-2 dropdown-style"
                type="text"
                :options="stateLoading ? null : countryStates"
                option-label="display"
                option-value="value"
              />
            </div>
            <small
              v-if="
                onSave && v$.$error && v$.basic.address_state.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Please select one option</small
            >
          </div>
        </td>
      </tr>
    </table>
    <div class="butwidth-style">
      <div class="edit-profile-updatebut-style">
        <td>
          <Button type="submit" :disabled="loading" class="my-profile-btn">
            <span class="p-button-label"
              ><i v-if="loading" class="fa fa-spinner fa-spin p-mr-2" />Save
            </span>
          </Button>
        </td>
        <td>
          <Button
            label="Cancel"
            :disabled="loading"
            class="p-button-outlined my-profile-btn"
            @click="editClose"
          />
        </td>
      </div>
    </div>
  </form>
</template>
<script>
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { updateProfileMixin } from '@/mixins/updateProfileMixin.js';
const charSpaceDot = (value) => new RegExp(/^[a-zA-Z. ]*$/).test(value);

export default {
  mixins: [updateProfileMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
    updateProfile: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  emits: ['close'],
  data: function () {
    return {
      onSave: false,
      v$: useValidate(),
      stateLoading: false,
      countryStates: null,
      basic: {
        address_country: '',
        address_state: '',
      },
      family_attributes: {
        father: '',
        mother: '',
        father_status: '',
        mother_status: '',
        family_type: '',
        no_of_married_brothers: 0,
        no_of_married_sisters: 0,
        no_of_unmarried_brothers: 0,
        no_of_unmarried_sisters: 0,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['selectOptions']),
  },
  created() {
    this.basic = { ...this.profile };
    this.family_attributes = { ...this.profile.family.attributes };
    if (this.basic.address_country) {
      this.getCountryStates();
    }
  },
  mounted() {
    this.v$.$validate();
  },
  validations() {
    return {
      basic: {
        address_country: { required },
        address_state: { required },
      },
      family_attributes: {
        father: { charSpaceDot },
        mother: { charSpaceDot },
      },
    };
  },
  methods: {
    getCountryStates() {
      this.stateLoading = true;
      let that = this;
      this.$http
        .get(
          `/users/country_states?country_names=${this.basic.address_country}`
        )
        .then((response) => {
          that.countryStates = response.data;
          that.stateLoading = false;
        });
      if (this.basic.address_country !== this.profile.address_country) {
        this.basic.address_state = null;
      }
    },
    update() {
      this.onSave = true;
      if (!this.v$.$error) {
        this.loading = true;
        this.updateProfile(
          {
            address_country: this.basic.address_country,
            address_state: this.basic.address_state,
            family_attributes: this.family_attributes,
          },
          'Family details updated successfully'
        ).then((result) => {
          this.loading = false;
          if (result) {
            this.$emit('close');
          }
        });
      }
    },
    editClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.edit-family-details {
  .p-inputtext {
    padding: 0.2rem 0.5rem;
  }
  .p-inputnumber-input {
    width: 92%;
  }
}
</style>
<style scoped lang="scss">
.input-align {
  display: flex;
}
.input-width {
  width: 100%;
}
.text-color {
  color: $errorColor;
}
.number-box {
  width: 92%;
}
.butwidth-style {
  width: 100%;
}
button {
  height: 38px;
}
.table-style {
  width: 80%;
}
h3 {
  width: 80%;
}
td {
  display: inline-flex;
  width: 50%;
  padding: 7px;
  padding-bottom: 0;
}
.radiobutton-style {
  margin-left: 5px;
}
.radio-style {
  margin-left: 5px;
  margin-right: 5%;
}

input {
  width: 92%;
}
.dropdown-style {
  width: 92%;
  height: 22px;
}
.p-button-outlined {
  border-color: $borderColor;
  color: $labelColor;
  justify-content: center;
}
</style>
