<template>
  <form @submit.prevent="update">
    <table class="table-style edit-personal-details c-form">
      <tr>
        <td>Height (fts)*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <InputNumber
                v-model="personal.height"
                class="p-ml-2 number-box"
                type="text"
                :min-fraction-digits="1"
              />
            </div>
            <small
              v-if="onSave && v$.$error && v$.personal.height.between.$invalid"
              class="text-color p-ml-3 p-d-block"
            >
              Please enter a valid height in-between 2.0 and 7.5 feets</small
            >
            <small
              v-if="onSave && v$.$error && v$.personal.height.required.$invalid"
              class="text-color p-ml-3 p-d-block"
            >
              Height is required</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>Weight (kgs)*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <InputNumber
                v-model="personal.weight"
                class="p-ml-2 number-box"
                type="text"
              />
            </div>
            <small
              v-if="onSave && v$.$error && v$.personal.weight.between.$invalid"
              class="text-color p-ml-3 p-d-block"
            >
              Please enter a valid weight in-between 30 and 200 kgs</small
            >
            <small
              v-if="onSave && v$.$error && v$.personal.weight.required.$invalid"
              class="text-color p-ml-3 p-d-block"
            >
              Weight is required</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>Differently Abled?*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <RadioButton
                v-model="personal.differently_abled"
                class="radiobutton-style"
                :value="true"
              />
              <label class="radio-style">Yes</label>
              <RadioButton
                v-model="personal.differently_abled"
                class="radiobutton-style"
                :value="false"
              />
              <label class="radio-style">No</label>
            </div>
            <small
              v-if="
                onSave &&
                v$.$error &&
                v$.personal.differently_abled.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Please select one value</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>Medical Condition*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <InputText
                v-model.trim="personal.medical_conditions"
                class="p-ml-2"
                type="text"
              />
            </div>
            <small
              v-if="
                onSave &&
                v$.$error &&
                v$.personal.medical_conditions.required.$invalid
              "
              class="text-color p-ml-3"
            >
              medical condition is Required</small
            >
          </div>
        </td>
      </tr>

      <tr>
        <td>Marital Status*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="basic.marital_status"
                class="p-ml-2 dropdown-style"
                type="text"
                :options="selectOptions.marital_statuses"
                option-label="display"
                option-value="value"
              />
            </div>
            <small
              v-if="
                onSave && v$.$error && v$.basic.marital_status.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Please select one option
            </small>
          </div>
        </td>
      </tr>

      <tr>
        <td>Food Preference*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <Dropdown
                v-model="basic.diet"
                class="p-ml-2 dropdown-style"
                type="text"
                :options="selectOptions.diets"
                option-label="display"
                option-value="value"
              />
            </div>
            <small
              v-if="onSave && v$.$error && v$.basic.diet.required.$invalid"
              class="text-color p-ml-3"
            >
              Please select one option
            </small>
          </div>
        </td>
      </tr>
    </table>

    <div class="butwidth-style">
      <div class="edit-profile-updatebut-style">
        <td>
          <Button type="submit" :disabled="loading" class="my-profile-btn">
            <span class="p-button-label"
              ><i v-if="loading" class="fa fa-spinner fa-spin p-mr-2" />Save
            </span>
          </Button>
        </td>
        <td>
          <Button
            label="Cancel"
            :disabled="loading"
            class="p-button-outlined my-profile-btn"
            @click="editClose"
          />
        </td>
      </div>
    </div>
  </form>
</template>
<script>
import useValidate from '@vuelidate/core';
import { required, between } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { updateProfileMixin } from '@/mixins/updateProfileMixin.js';
export default {
  mixins: [updateProfileMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
    updateProfile: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  emits: ['close'],
  data() {
    return {
      onSave: false,
      v$: useValidate(),
      basic: {
        marital_status: null,
        diet: null,
      },
      personal: {
        height: null,
        weight: null,
        medical_conditions: '',
        differently_abled: false,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['selectOptions']),
  },
  created() {
    this.personal = { ...this.profile.physical_info.attributes };
    this.basic = { ...this.profile };
  },
  mounted() {
    this.v$.$validate();
  },
  validations() {
    return {
      basic: {
        marital_status: { required },
        diet: { required },
      },
      personal: {
        height: { required, between: between(2.0, 7.5) },
        weight: { required, between: between(30, 200) },
        medical_conditions: { required },
        differently_abled: { required },
      },
    };
  },

  methods: {
    update() {
      this.onSave = true;
      if (!this.v$.$error) {
        this.loading = true;
        this.updateProfile(
          {
            marital_status: this.basic.marital_status,
            diet: this.basic.diet,
            physical_info_attributes: this.personal,
          },
          'Personal details updated successfully'
        ).then((result) => {
          this.loading = false;
          if (result) {
            this.$emit('close');
          }
        });
      }
    },
    editClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.edit-personal-details {
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 8px;
    height: 8px;
  }
  .p-radiobutton .p-radiobutton-box {
    width: 16px;
    height: 16px;
  }
  .p-radiobutton {
    transform: translateY(2px);
    width: 16px;
    height: 16px;
  }

  .p-inputtext {
    padding: 0.2rem 0.5rem;
  }
  .p-inputnumber-input {
    width: 92%;
  }
}
</style>
<style scoped lang="scss">
.input-align {
  display: flex;
}
.input-width {
  width: 100%;
}
.text-color {
  color: $errorColor;
}
.number-box {
  width: 92%;
}
.butwidth-style {
  width: 100%;
}
button {
  height: 38px;
}
.table-style {
  width: 80%;
}
h3 {
  width: 80%;
}
td {
  display: inline-flex;
  width: 50%;
  padding: 7px;
  padding-bottom: 0;
}
.radiobutton-style {
  margin-left: 5px;
}
.radio-style {
  margin-left: 5px;
  margin-right: 5%;
}
input {
  width: 92%;
}
.dropdown-style {
  width: 92%;
  height: 22px;
}
.p-button-outlined {
  border-color: $borderColor;
  color: $labelColor;
  justify-content: center;
}
</style>
