<template>
  <div class="header-style c-form">
    <h3>Social Details</h3>
    <Button
      v-if="showDetails"
      icon="pi pi-pencil"
      icon-pos="left"
      label="Edit"
      class="p-button-sm my-profile-btn"
      @click="toggleEdit()"
    />
    <Button
      v-else
      icon="pi pi-times"
      icon-pos="left"
      label="Close"
      class="p-button-sm my-profile-btn"
      @click="toggleEdit()"
    />
  </div>

  <view-social-details v-if="showDetails" :profile="profile" />
  <edit-social-details
    v-else
    :profile="profile"
    :update-profile="updateProfile"
    @close="toggleEdit"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import EditSocialDetails from './EditSocialDetails.vue';
import ViewSocialDetails from './ViewSocialDetails.vue';
import { updateProfileMixin } from '@/mixins/updateProfileMixin.js';
export default {
  components: {
    ViewSocialDetails,
    EditSocialDetails,
  },
  mixins: [updateProfileMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
    updateProfile: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  emits: ['update'],
  data() {
    return {
      showDetails: true,
    };
  },
  computed: {
    ...mapGetters(['authUser']),
  },
  created() {
    this.showDetails = this.profile.social_class.attributes.citizenship !== '';
  },
  methods: {
    toggleEdit() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style scoped>
button {
  height: 32px;
  min-width: 60px;
}
h3 {
  font-size: 22px;
}
.header-style {
  display: flex;
  justify-content: space-between;
}
</style>
