<template>
  <div class="header-style c-form">
    <h3>Contact Details</h3>
    <Button
      v-if="showDetails"
      icon="pi pi-pencil"
      icon-pos="left"
      label="Edit"
      class="p-button-sm my-profile-btn"
      @click="toggleEdit()"
    />
    <Button
      v-else
      icon="pi pi-times"
      icon-pos="left"
      label="Close"
      class="p-button-sm my-profile-btn"
      @click="toggleEdit()"
    />
  </div>
  <view-contact-Show-details v-if="showDetails" :profile="profile" />
  <edit-contact-show-details
    v-else
    :basic="profile"
    :update-profile="updateProfile"
    @changedNo="changedNo"
    @close="toggleEdit"
  />
</template>
<script>
import ViewContactShowDetails from './ViewContactShowDetails.vue';
import EditContactShowDetails from './EditContactShowDetails.vue';
import { mapGetters } from 'vuex';
import { updateProfileMixin } from '@/mixins/updateProfileMixin.js';

export default {
  components: {
    ViewContactShowDetails,
    EditContactShowDetails,
  },
  mixins: [updateProfileMixin],
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
    updateProfile: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  emits: ['changedNo'],
  data() {
    return {
      showDetails: true,
    };
  },
  computed: {
    ...mapGetters(['authUser']),
  },
  created() {
    this.showDetails = this.profile.contact_name !== '';
  },
  methods: {
    toggleEdit() {
      this.showDetails = !this.showDetails;
    },
    changedNo(num) {
      this.$emit('changedNo', num);
    },
  },
};
</script>
<style scoped>
button {
  height: 32px;
  min-width: 60px;
}
h3 {
  font-size: 22px;
}
.header-style {
  display: flex;
  justify-content: space-between;
}
</style>
