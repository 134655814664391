<template>
  <form @submit.prevent="updateContactDetails">
    <table class="table-style edit-basic-details c-form">
      <tr>
        <td>Name*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <InputText
                v-model.trim="profile.contact_name"
                class="p-ml-2"
                type="text"
              />
            </div>
            <small
              v-if="
                onSave && v$.$error && v$.profile.contact_name.required.$invalid
              "
              class="text-color p-ml-3 p-d-block"
              >Name is Required</small
            >
            <small
              v-if="
                v$.profile.contact_name.charSpaceDot.$invalid &&
                profile.contact_name.length !== 0
              "
              class="text-color p-ml-3 p-d-block"
              >Only alphabets, space and dot(.) are allowed</small
            >
          </div>
        </td>
      </tr>
      <tr>
        <td>Relation*</td>
        <td>
          <div class="input-width">
            <div class="input-align">
              :
              <InputText
                v-model.trim="profile.contact_relation"
                class="p-ml-2"
                type="text"
              />
            </div>
            <small
              v-if="
                onSave &&
                v$.$error &&
                v$.profile.contact_relation.required.$invalid
              "
              class="text-color p-ml-3 p-d-block"
              >Relation is Required</small
            >
            <small
              v-if="
                v$.profile.contact_relation.charSpaceDot.$invalid &&
                profile.contact_relation.length !== 0
              "
              class="text-color p-ml-3 p-d-block"
              >Only alphabets, space and dot(.) are allowed</small
            >
          </div>
        </td>
      </tr>
      <tr>
        <td>Mobile No*</td>
        <td>
          <div class="input-width">
            <div
              v-if="profile.primary_contact_number === ''"
              class="p-inputgroup"
            >
              :
              <Button
                label="Add Number"
                class="p-button-outlined btn-size"
                @click="changeNumber()"
              />
            </div>

            <div v-else class="p-inputgroup">
              :
              <InputNumber
                v-model="profile.primary_contact_number"
                prefix="+91 "
                class="p-ml-2"
                :disabled="true"
                :use-grouping="false"
              />
              <Button
                icon="pi pi-pencil"
                class="num-edit"
                @click="changeNumber()"
              />
            </div>
            <small
              v-if="
                onSave &&
                v$.$error &&
                v$.profile.primary_contact_number.required.$invalid
              "
              class="text-color p-ml-3"
            >
              Mobile Number is Required</small
            >
            <mobile-verification-dialog
              v-if="showDialog"
              :mobile="profile.primary_contact_number"
              @close="cancelVerification"
              @changedNo="changedNo"
            />
          </div>
        </td>
      </tr>
    </table>
    <div class="butwidth-style">
      <div class="edit-profile-updatebut-style">
        <td>
          <Button type="submit" :disabled="loading" class="my-profile-btn">
            <span class="p-button-label"
              ><i v-if="loading" class="fa fa-spinner fa-spin p-mr-2" />Save
            </span>
          </Button>
        </td>
        <td>
          <Button
            label="Cancel"
            :disabled="loading"
            class="p-button-outlined my-profile-btn"
            @click="editClose"
          />
        </td>
      </div>
    </div>
  </form>
</template>
<script>
import mobileVerificationDialog from '@/components/myprofile/mobileVerificationDialog';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { updateProfileMixin } from '@/mixins/updateProfileMixin.js';
const charSpaceDot = (value) => new RegExp(/^[a-zA-Z .]+$/).test(value);

export default {
  components: {
    mobileVerificationDialog,
  },
  mixins: [updateProfileMixin],
  props: {
    basic: {
      type: Object,
      default() {
        return {};
      },
    },
    updateProfile: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'changedNo'],
  data() {
    return {
      onSave: false,
      showDialog: false,
      v$: useValidate(),
      profile: {
        Contact_name: '',
        contact_relation: '',
        primary_contact_number: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['selectOptions']),
  },
  created() {
    this.profile = { ...this.basic };
  },
  mounted() {
    this.v$.$validate();
  },
  validations() {
    return {
      profile: {
        contact_name: {
          required,
          charSpaceDot,
        },
        contact_relation: {
          required,
          charSpaceDot,
        },
        primary_contact_number: { required },
      },
    };
  },
  methods: {
    updateContactDetails() {
      this.onSave = true;
      if (!this.v$.$error) {
        this.loading = true;
        this.updateProfile(
          {
            primary_contact_number: this.profile.primary_contact_number,
            contact_name: this.profile.contact_name,
            contact_relation: this.profile.contact_relation,
          },
          'Contact details updated successfully'
        ).then((result) => {
          this.loading = false;
          if (result) {
            this.$emit('close');
          }
        });
      }
    },

    editClose() {
      this.$emit('close');
    },
    changeNumber() {
      this.showDialog = true;
    },
    cancelVerification() {
      this.showDialog = false;
    },
    changedNo(num) {
      this.profile.primary_contact_number = num;
      this.$emit('changedNo', num);
    },
  },
};
</script>
<style lang="scss">
.edit-basic-details {
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 8px;
  }
  .p-radiobutton .p-radiobutton-box {
    width: 16px;
  }
  .p-radiobutton {
    transform: translateY(2px);
    width: 16px;
  }
  .p-inputtext {
    padding: 0.2rem 0.5rem;
  }
  .p-inputgroup {
    width: 96%;
  }
  .p-dropdown-trigger {
    width: unset;
    margin-right: 5px;
  }
  .p-calendar-w-btn {
    height: 24px;
  }
  .p-calendar {
    width: 92%;
  }
  .p-button.p-button-icon-only {
    height: 24px;
  }
  .p-dropdown-label {
    &.p-placeholder {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}
.time-format {
  &.p-dropdown {
    border: 1px solid #e91e63;
  }
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;
}
</style>
<style scoped lang="scss">
.p-inputgroup {
  height: 24px;
}
.number-prefix {
  background: black;
  width: 10px;
}
.align-btn {
  display: flex;
}
.radio-btn-align {
  display: flex;
  flex-wrap: wrap;
}
.btn-size {
  width: 96%;
  height: 24px;
  margin-left: 7px;
}
.num-edit {
  height: 24px;
  color: $backgroundColor;
  background: $primaryColor;
  border: 1px solid $primaryColor;
}
.input-align {
  display: flex;
}
.input-width {
  width: 100%;
}
.text-color {
  color: $errorColor;
}
li {
  list-style-type: none;
  display: contents;
}
.butwidth-style {
  width: 100%;
}
button {
  height: 38px;
}
.table-style {
  width: 80%;
}
h3 {
  width: 80%;
}
td {
  display: inline-flex;
  width: 50%;
  padding: 7px;
  padding-bottom: 0;
}
.radiobutton-style {
  margin-left: 5px;
}
.radio-style {
  margin-left: 5px;
  margin-right: 5%;
}
input {
  width: 92%;
}
.dropdown-style {
  width: 92%;
}
.p-button-outlined {
  border-color: $borderColor;
  color: $labelColor;
  justify-content: center;
}
</style>
